import Intercom from '@intercom/messenger-js-sdk';
import i18n from 'i18next';
import React, {
  useEffect,
  useRef,
  useLayoutEffect,
  useState,
} from 'react';
import {
  BrowserRouter, Redirect, Route, Switch,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { connect, useDispatch } from 'react-redux';
import Toaster from 'mkit-front-ds/dist/@setproduct-ui/core/Toast/Toaster';
import toastRef from 'mkit-front-ds/dist/helpers/toast';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { ModuleRegistry } from '@ag-grid-community/core';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import Spinner from 'mkit-front-ds/dist/@setproduct-ui/core/Spinner';

import { useDictionariesApi, useUsersApi, useCompaniesApi } from 'hooks/api';
import { useToken, useTheme } from 'hooks';
import { AppRoutes } from 'components/routes';
import ModalProvider from 'components/Modal/ModalProvider';
import endpoints from 'const/endpoints';
import SignInScreen from 'screens/SignInScreen';
import SignUpScreen from 'screens/SignUpScreen';
import SecondStepSignUpScreen from 'screens/SecondStepSignUpScreen';

import 'react-toastify/dist/ReactToastify.css';

LicenseManager.setLicenseKey('CompanyName=Alarislabs,LicensedApplication=InVoice,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-026846,ExpiryDate=30_March_2023_[v2]_MTY4MDEzMDgwMDAwMA==b027f497102f6736b1d7fe91287990b5');
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MasterDetailModule,
]);

let tokenTimeoutId;
let activityTimeoutId;

const App = ({ menuExpand }) => {
  const {
    token,
    tokenExpiresIn,
    setToken,
    setTokenExpiresIn,
    refreshTokenExpiresIn,
    setRefreshTokenExpiresIn,
    setIdToken,
    resetAuth,
  } = useToken();
  const { setDarkTheme } = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getAvailableChannels, lastUpdatedGetAvailableChannels } = useDictionariesApi();
  const { getCurrentUser, currentUser } = useUsersApi();
  const { getOwnCompany, lastUpdatedGetOwnCompany, ownCompany } = useCompaniesApi();
  const isAuthInitialized = useRef(false);
  const [isPendingGetPortal, setIsPendingGetPortal] = useState(true);

  const refreshToken = () => {
    fetch(endpoints.getRefreshTokenUrl(), {
      method: 'POST',
      credentials: process.env.NODE_ENV === 'production' ? undefined : 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setToken(data.token);
        setTokenExpiresIn(moment().add(data.expiresIn, 'seconds'));
        setRefreshTokenExpiresIn(moment().add(data.refreshExpiresIn, 'seconds'));
        setIdToken(data.idToken);
      })
      .catch((error) => {
        console.error('ERROR:', error);
        resetAuth();
      });
  };
  const toastRefUpdater = (ref) => {
    if (ref) {
      toastRef.current = ref;
      if (!toastRef.current?.showMessage) {
        toastRef.current.showMessage = ({ message, intent = 'danger', ...rest }) =>
          toastRef.current.show({
            message: typeof message === 'object' ? t(message.key, { ...message.values }) : t(message),
            intent,
            ...rest,
          });
      }
    }
  };
  const init = async () => {
    if (token) {
      // если в хранилище есть информация о предыдущей сессии
      if (moment(tokenExpiresIn).diff(moment(), 'seconds') > 0) {
        // если токен жив - пускаем App дальше для запуска приложения
      } else if (moment(refreshTokenExpiresIn).diff(moment(), 'seconds') > 0) {
        // если рефреш токен жив - запрашиваем рефреш
        await refreshToken();
      } else {
        // иначе скидываем информацию о предыдущей сессии
        resetAuth();
      }
    }
    isAuthInitialized.current = true;
  };
  const restartAutoReset = () => {
    if (activityTimeoutId) {
      clearTimeout(activityTimeoutId);
    }
    activityTimeoutId = setTimeout(() => {
      fetch(endpoints.getLogoutUrl(), {
        method: 'POST',
        credentials: process.env.NODE_ENV === 'production' ? undefined : 'include',
      })
        .then(() => {
          resetAuth();
        });
    }, 1000 * 60 * 60); // 60 min
  };

  if (!isAuthInitialized.current) {
    init();
  }

  useEffect(() => {
    if (tokenTimeoutId) {
      clearTimeout(tokenTimeoutId);
    }
    const tokenExpiresInDiff = moment(tokenExpiresIn).diff(moment(), 'seconds');

    if (tokenExpiresInDiff > 0) {
      const timeout = (tokenExpiresInDiff - (tokenExpiresInDiff * 0.1)) * 1000;

      getCurrentUser((data) => {
        setDarkTheme(data.darkTheme);
      });
      if (!lastUpdatedGetAvailableChannels) {
        getAvailableChannels(token);
      }
      if (!lastUpdatedGetOwnCompany) {
        getOwnCompany();
      }
      tokenTimeoutId = setTimeout(refreshToken, (timeout < 0 ? 0 : timeout));
    }
  }, [tokenExpiresIn]);
  useLayoutEffect(() => {
    fetch(endpoints.getPortalAssetsUrl('portal.json'))
      .then(res => res.json())
      .then(async (res) => {
        if (res?.pageTitle) {
          document.title = res.pageTitle;
        }
        if (res?.favicon) {
          const link = document.createElement('link');
          link.type = 'image/x-icon';
          link.rel = 'icon';
          link.href = endpoints.getPortalAssetsUrl(res.favicon);

          const prevLink = document.querySelector("link[rel*='icon']");
          if (prevLink) {
            document.head.removeChild(prevLink);
          }
          document.head.appendChild(link);
        }
        if (res?.languages) {
          dispatch({ type: 'setAvailableLanguages', languages: res.languages });
        }

        await localStorage.setItem('mkitPortal', JSON.stringify(res));
        setIsPendingGetPortal(false);
      })
      .catch(() => setIsPendingGetPortal(false));
  }, []);
  useEffect(() => {
    window.addEventListener('touchmove', restartAutoReset);
    window.addEventListener('mousemove', restartAutoReset);
  }, []);
  useEffect(() => {
    if (token
        && currentUser?.intercomUnique?.enableIntercom
        && currentUser?.intercomUnique?.intercomAppId
    ) {
      Intercom({
        app_id: currentUser?.intercomUnique?.intercomAppId,
        user_id: currentUser?.id,
        user_hash: currentUser?.intercomUnique?.secret,
        name: `${currentUser?.firstName} ${currentUser?.lastName}`,
        email: currentUser?.email,
        language_override: i18n.language,
        created_at: new Date(currentUser?.createdAt).getTime(),
      });
    }
  }, [currentUser]);

  if (!isAuthInitialized.current
    || (token && (isEmpty(currentUser)
    || isEmpty(ownCompany)))
    || isPendingGetPortal) {
    return null;
  }

  return (
    <ModalProvider>
      <ToastContainer
        autoClose={5000}
        style={{ marginLeft: menuExpand ? 175 : 20 }}
      />
      <Toaster
        ref={toastRefUpdater}
        withoutClose={false}
        position="top"
        view="outlined"
        usePortal
        maxToasts={3}
        className="toast"
        timeout={5000}
      />
      <BrowserRouter>
        {token ? (
          lastUpdatedGetAvailableChannels ? <AppRoutes /> : <Spinner className="app-spinner" />
        ) : (
          <Switch>
            <Route path="/sign-in">
              <SignInScreen />
            </Route>
            <Route path="/sign-up">
              <SignUpScreen />
            </Route>
            <Route path="/activate">
              <SecondStepSignUpScreen />
            </Route>
            <Route path="*">
              <Redirect to={`/sign-in?redirectTo=${window.location.pathname}`} />
            </Route>
          </Switch>
        )}
      </BrowserRouter>
    </ModalProvider>
  );
};

const mapStateToProps = state => ({
  menuExpand: state.layout.menuExpand,
});

export default connect(mapStateToProps)(App);
